<template>
  <!-- <basic-container>
  <head-layout :head-title="$t('cip.dc.datacolumn.field.title')" style="margin-bottom: 10px;"></head-layout>
  <el-row :gutter="10" style="display:flex;">
    <el-col :span="4" style="position: relative;">
      <el-card class="box-card tree-wrapper" shadow="always">
        <el-tree
          ref="tree"
          :data="treeOptions"
          node-key="id"
          :empty-text="$t('cip.dc.datacolumn.field.load')"
          :props="defaultProps"
          highlight-current
          :expand-on-click-node="false"
          @node-click="handleNodeClick"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <i v-if="node.level === 1" class="iconfont icon-shujuku"></i>
            <i v-else-if="node.level === 2" class="iconfont icon-shujubiao"></i>
            <span>{{ node.label }}</span>
          </span>
        </el-tree>
      </el-card>
    </el-col>
    <el-col :span="20">
      <el-card class="box-card" shadow="always">
        <el-form ref="queryForm" :model="queryParams" :inline="true">
          <el-form-item :label="$t('cip.dc.datacolumn.field.fieldName')" prop="columnName">
            <el-input
              v-model="queryParams.columnName"
              :placeholder="$t('cip.cmn.rule.inputWarning')+$t('cip.dc.datacolumn.field.fieldName')"
              clearable
              size="small"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery"></el-button>
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"></el-button>
          </el-form-item>
        </el-form>


        <el-table
          v-loading="loading"
          :data="columnList"
          border
          tooltip-effect="dark"
          :size="tableSize"
          style="width: 100%;margin: 15px 0;"
          :height="tableHeight"
        >
          <el-table-column type="selection" width="55" align="center" />
          <el-table-column :label="$t('datacenter.dataQuality.index')" width="55" align="center">
            <template slot-scope="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <template v-for="(item, index) in tableColumns">
            <el-table-column
              v-if="item.show"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              :formatter="item.formatter"
              align="center"
              show-overflow-tooltip
            />
          </template>
          <el-table-column :label="$t('sinoma.btn.handel')" align="center" class-name="small-padding fixed-width">
            <template slot-scope="scope">
              <el-popover
                placement="left"
                trigger="click"
              >
                <el-button
                  v-hasPerm="['metadata:datacolumn:detail']"
                  size="mini"
                  type="text"
                  icon="el-icon-view"
                  @click="handleDetail(scope.row)"
                >{{$t('sinoma.btn.detail')}}</el-button>
                <el-button slot="reference" size="mini" type="text">{{$t('sinoma.btn.handel')}}</el-button>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="queryParams.pageNum"
          :page-size.sync="queryParams.pageSize"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-card>
    </el-col>
  </el-row>
  </basic-container> -->
  <div>
    <el-container>
      <CommonTree
        :treeData="treeOptions"
        :defaultProps="defaultProps"
        :searchTitle="searchTitle"
        :isShowdig="false"
        :showCheckbox="false"
        @getNodeClick="handleNodeClick"
        style="width: 205px;"
        :treeExpand="false"
      />
      <el-container>
        <el-main>
          <head-layout :head-title="$t('cip.dc.datacolumn.title')" ></head-layout>

        <div>
        <el-form ref="queryForm" :model="queryParams" :inline="true" class="search-form">
          <el-form-item  prop="columnName">
            <el-input
              v-model="queryParams.columnName"
              :placeholder="$t('cip.cmn.rule.inputWarning')+$t('cip.dc.datacolumn.field.fieldName')"
              clearable
              size="small"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery"></el-button>
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"></el-button>
          </el-form-item>
        </el-form>
</div>

<grid-layout
            ref="gridLayout"
            :data-total="total"
            @page-size-change="handleSizeChange"
            @page-current-change="handleCurrentChange"
            @page-refresh-change="onLoad"
            :page="page"
            @gird-handle-select-click="selectionChange"
            :tableOptions="tableOptions"
            :tableData="columnList"
            :tableLoading="tableLoading"
            :searchColumns="searchColumns"

          >
           <!-- :gridRowBtn="gridRowBtn"
            @grid-edit="handleDetail" -->
          </grid-layout>

        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { pageDataColumn, getDataMetadataTree } from '@/api/metadata/datacolumn'
import GridLayout from "@/views/components/layout/grid-layout";

import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import CommonTree from "@/views/components/com_tree/index"
export default {
  name: 'DataColumnList',
  components:{
    CommonTree,
    GridLayout
  },
  data() {
    return {
      tableHeight: document.body.offsetHeight - 305 + 'px',
      // 展示切换
      showOptions: {
        data: {},
        showList: true,
        showDetail: false
      },
      // 遮罩层
      loading: true,
      // 表格头
      tableColumns: [
        { prop: 'columnName', label: this.$t('cip.dc.datacolumn.field.fieldName'), show: true },
        { prop: 'columnComment', label: this.$t('cip.dc.datacolumn.field.fieldComment'), show: true },
        { prop: 'columnKey', label: this.$t('cip.dc.datacolumn.field.isPrimaryKey'), show: true, formatter: this.keyFormatter },
        { prop: 'columnNullable', label: this.$t('cip.dc.datacolumn.field.isEmpty'), show: true, formatter: this.nullableFormatter },
        { prop: 'dataType', label: this.$t('cip.dc.datacolumn.field.dataType'), show: true },
        { prop: 'dataLength', label: this.$t('cip.dc.datacolumn.field.dataLength'), show: true },
        { prop: 'dataPrecision', label: this.$t('cip.dc.datacolumn.field.dataPrecision'), show: true },
        { prop: 'dataScale', label: this.$t('cip.dc.datacolumn.field.dataScale'), show: true },
        { prop: 'dataDefault', label: this.$t('cip.dc.datacolumn.field.dataDefault'), show: true }
      ],
      // 默认选择中表格头
      checkedTableColumns: [],
      tableSize: 'small',
      // 表格数据
      columnList: [],
      // 总数据条数
      total: 0,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 20,
        columnName: '',
        tableId: '',
        sourceId:"",
      },
      // 左侧树
      treeOptions: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      searchTitle: 'label',
      tableLoading:false,
      tableOptions: {
        // customRowAddBtn: false,
        // menuWidth: 100,
        menu:false,
        column: [
          {
            label: this.$t('cip.dc.datacolumn.field.fieldName'),
            prop: "columnName",
            cell: true,
          },
          {
            label: this.$t('cip.dc.datacolumn.field.fieldComment'),
            prop: "columnComment",
            cell: true,
            overHidden:true
          },
          {
            label: this.$t('cip.dc.datacolumn.field.isPrimaryKey'),
            prop: "columnKey",
            cell: true,
          },
          {
            label: this.$t("cip.dc.datacolumn.field.isEmpty"),
            prop: "columnNullable",
            cell: true,
          },

          {
            label: this.$t('cip.dc.datacolumn.field.dataType'),
            prop: "dataType",
            cell: true,
          },
          {
            label: this.$t("cip.dc.datacolumn.field.dataLength"),
            prop: "dataLength",
            cell: true,
          },



          {
            label: this.$t("cip.dc.datacolumn.field.dataPrecision"),
            prop: "dataPrecision",
            cell: true,
          },

          {
            label: this.$t('cip.dc.datacolumn.field.dataScale'),
            prop: "dataScale",
            cell: true,
          },
          {
            label: this.$t("cip.dc.datacolumn.field.dataDefault"),
            prop: "dataDefault",
            cell: true,
          },


        ],

      },
      page: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0
      },
      gridRowBtn: [
        {
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "grid-edit",
          remark: 'edit',
          type: "text",
          icon: ""
        },


      ],
    }
  },
  created() {

        this.queryParams.pageSize = 10,
        this.queryParams.pageNum = 1,
        this.queryParams.tableId = undefined,
        this.queryParams.columnName = undefined,
        this.queryParams.sourceId = undefined,

    this.getList()
    this.getTree()
  },
  mounted() {
    this.initCols()
  },
  methods: {
    /** 查询列表 */
    getList() {
      this.loading = true
      pageDataColumn(this.queryParams).then(res => {
        // let response = res.data;
        this.loading = false
        // if (response.success) {
        //   const { data } = response
        //   this.columnList = data.data
        //   this.total = data.total
        // }
        this.columnList = res.data.data.dataList
        this.total = res.data.data.page.totalSize
      })
    },
    /** 查询树结构 */
    getTree() {
      getDataMetadataTree('table').then(res => {
        let response = res.data;
        if (response.success) {
          const { data } = response
          this.treeOptions = data
        }
      })
    },
    initCols() {
      this.checkedTableColumns = this.tableColumns.map(col => col.prop)
    },
    handleCheckedColsChange(val) {
      this.tableColumns.forEach(col => {
        if (!this.checkedTableColumns.includes(col.prop)) {
          col.show = false
        } else {
          col.show = true
        }
      })
    },
    handleCommand(command) {
      this.tableSize = command
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 20,
        columnName: '',
        tableId: ''
      }
      this.handleQuery()
    },
    /** 刷新列表 */
    handleRefresh() {
      this.getList()
    },
    /** 详情按钮操作 */
    handleDetail(row) {
      this.showOptions.data.id = row.id
      this.showOptions.showList = false
      this.showOptions.showDetail = true
      this.$emit('showCard', this.showOptions)
    },
    handleSizeChange(val) {
      this.page.pageSize = val.pageSize
      console.log(`每页 ${val} 条`)
      this.queryParams.pageNum = 1
      this.queryParams.pageSize = val.pageSize
      this.getList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page.currentPage = val.currentPage
      this.queryParams.pageNum = val.currentPage
      this.getList()
    },
    /** 节点单击事件 */
    handleNodeClick(data,node) {
      console.log(data,"xxxxx",node.parent.data.id);
      if (data.type === 'database') {
        this.queryParams.tableId = ''
      } else if (data.type === 'table') {
        this.queryParams.tableId = data.id
      }
      if(!node.parent.data.id){
        console.log("eeeee");
        this.queryParams.sourceId = data.id
        this.queryParams.tableId = undefined
      }else{
        this.queryParams.sourceId = node.parent.data.id
      }

      this.queryParams.pageNum = 1
      this.page.currentPage = 1
      this.getList()
    },
    keyFormatter(row, column, cellValue, index) {
      if (cellValue === '1') {
        return 'Y'
      } else {
        return 'N'
      }
    },
    nullableFormatter(row, column, cellValue, index) {
      if (cellValue === '1') {
        return 'Y'
      } else {
        return 'N'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.search-form::v-deep{
   background: #FFFFFF;
   padding: 14px 12px;
  .el-form-item--small .el-form-item__content{
    line-height: 28px !important;
  }
  .el-button{
    height: 28px;
    margin-left:0;
   }
  .el-input__inner{
   height: 28px;
   line-height: 28px;
  }
}
.right-toolbar {
  float: right;
}
.tree-wrapper {
  position: absolute;
  overflow-y: auto;
  height: 100%;
  left: 5px;
  right: 5px;
  ::v-deep .custom-tree-node {
    flex: 1;
    display: flex;
    font-size: 14px;
    i {
      // margin-right: 10px;
    }
    .icon-shujuku {
      color: #20a0ff;
    }
    .icon-shujubiao {
      color: #38dcec;
    }
  }
}
::v-deep .search-form .el-form-item--small.el-form-item{
    margin-bottom: 0;
  }
</style>
