var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-card",
    { staticClass: "box-card", attrs: { shadow: "always" } },
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [
          _c("span", [_vm._v(_vm._s(_vm.title))]),
          _c(
            "el-button-group",
            { staticStyle: { float: "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", icon: "el-icon-back", round: "" },
                  on: { click: _vm.showCard },
                },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "body-wrapper" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.form, "label-width": "100px", size: "mini" },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("cip.dc.datacolumn.field.fieldName"),
                            prop: "columnName",
                          },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.form.columnName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "columnName", $$v)
                                },
                                expression: "form.columnName",
                              },
                            },
                            [
                              _c("el-button", {
                                directives: [
                                  {
                                    name: "hasPerm",
                                    rawName: "v-hasPerm",
                                    value: ["metadata:changerecord:add"],
                                    expression: "['metadata:changerecord:add']",
                                  },
                                ],
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-edit-outline",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeRecord("columnName")
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "cip.dc.datacolumn.field.fieldComment"
                            ),
                            prop: "columnComment",
                          },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.form.columnComment,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "columnComment", $$v)
                                },
                                expression: "form.columnComment",
                              },
                            },
                            [
                              _c("el-button", {
                                directives: [
                                  {
                                    name: "hasPerm",
                                    rawName: "v-hasPerm",
                                    value: ["metadata:changerecord:add"],
                                    expression: "['metadata:changerecord:add']",
                                  },
                                ],
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-edit-outline",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeRecord("columnComment")
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "cip.dc.datacolumn.field.dataDefault"
                            ),
                            prop: "dataDefault",
                          },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.form.dataDefault,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "dataDefault", $$v)
                                },
                                expression: "form.dataDefault",
                              },
                            },
                            [
                              _c("el-button", {
                                directives: [
                                  {
                                    name: "hasPerm",
                                    rawName: "v-hasPerm",
                                    value: ["metadata:changerecord:add"],
                                    expression: "['metadata:changerecord:add']",
                                  },
                                ],
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-edit-outline",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeRecord("dataDefault")
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "cip.dc.datacolumn.field.isPrimaryKey"
                            ),
                            prop: "columnKey",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.columnKey === "1" ? "Y" : "N",
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "columnKey === '1' ? 'Y' : 'N'",
                                  $$v
                                )
                              },
                              expression: "form.columnKey === '1' ? 'Y' : 'N'",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("cip.dc.datacolumn.field.isEmpty"),
                            prop: "columnNullable",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value:
                                _vm.form.columnNullable === "1" ? "Y" : "N",
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "columnNullable === '1' ? 'Y' : 'N'",
                                  $$v
                                )
                              },
                              expression:
                                "form.columnNullable === '1' ? 'Y' : 'N'",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("cip.dc.datacolumn.field.dataType"),
                            prop: "dataType",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.dataType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "dataType", $$v)
                              },
                              expression: "form.dataType",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("cip.dc.datacolumn.field.dataLength"),
                            prop: "dataLength",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.dataLength,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "dataLength", $$v)
                              },
                              expression: "form.dataLength",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "cip.dc.datacolumn.field.dataPrecision"
                            ),
                            prop: "dataPrecision",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.dataPrecision,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "dataPrecision", $$v)
                              },
                              expression: "form.dataPrecision",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("cip.dc.datacolumn.field.dataScale"),
                            prop: "dataScale",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.dataScale,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "dataScale", $$v)
                              },
                              expression: "form.dataScale",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider", { attrs: { "content-position": "center" } }, [
            _vm._v("变更记录"),
          ]),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%", margin: "15px 0" },
                      attrs: {
                        data: _vm.changeData.dataList,
                        stripe: "",
                        border: "",
                        "max-height": 200,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { label: "序号", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [_vm._v(_vm._s(scope.$index + 1))]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "sourceName",
                          label: _vm.$t("cip.dc.datacolumn.field.sourceName"),
                          align: "center",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "tableName",
                          label: _vm.$t("cip.dc.datacolumn.field.tableName"),
                          align: "center",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "fieldName",
                          label: _vm.$t("cip.dc.datacolumn.field.updateField"),
                          align: "center",
                          "show-overflow-tooltip": "",
                          formatter: _vm.fieldNameFormatter,
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "version",
                          label: _vm.$t("cip.dc.datacolumn.field.version"),
                          align: "center",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "fieldOldValue",
                          label: _vm.$t(
                            "cip.dc.datacolumn.field.fieldOldValue"
                          ),
                          align: "center",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "fieldNewValue",
                          label: _vm.$t(
                            "cip.dc.datacolumn.field.fieldNewValue"
                          ),
                          align: "center",
                          "show-overflow-tooltip": "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [10, 20, 50, 100],
                      layout: "total, sizes, prev, pager, next, jumper",
                      "current-page": _vm.changeData.pageNum,
                      "page-size": _vm.changeData.pageSize,
                      total: _vm.changeData.dataTotal,
                    },
                    on: {
                      "update:currentPage": function ($event) {
                        return _vm.$set(_vm.changeData, "pageNum", $event)
                      },
                      "update:current-page": function ($event) {
                        return _vm.$set(_vm.changeData, "pageNum", $event)
                      },
                      "update:pageSize": function ($event) {
                        return _vm.$set(_vm.changeData, "pageSize", $event)
                      },
                      "update:page-size": function ($event) {
                        return _vm.$set(_vm.changeData, "pageSize", $event)
                      },
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.record.title,
            visible: _vm.record.open,
            "min-width": "600px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.record, "open", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form2",
              attrs: {
                model: _vm.form2,
                rules: _vm.rules2,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("cip.dc.datacolumn.field.updateField"),
                    prop: "fieldName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder:
                        _vm.$t("cip.cmn.rule.inputWarning") +
                        _vm.$t("cip.dc.datacolumn.field.updateField"),
                      disabled: true,
                    },
                    model: {
                      value: _vm.form2.fieldName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form2, "fieldName", $$v)
                      },
                      expression: "form2.fieldName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("cip.dc.datacolumn.field.version"),
                    prop: "version",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder:
                        _vm.$t("cip.cmn.rule.inputWarning") +
                        _vm.$t("cip.dc.datacolumn.field.version"),
                    },
                    model: {
                      value: _vm.form2.version,
                      callback: function ($$v) {
                        _vm.$set(_vm.form2, "version", $$v)
                      },
                      expression: "form2.version",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("cip.dc.datacolumn.field.fieldOldValue"),
                    prop: "fieldOldValue",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder:
                        _vm.$t("cip.cmn.rule.inputWarning") +
                        _vm.$t("cip.dc.datacolumn.field.fieldOldValue"),
                      disabled: true,
                    },
                    model: {
                      value: _vm.form2.fieldOldValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.form2, "fieldOldValue", $$v)
                      },
                      expression: "form2.fieldOldValue",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("cip.dc.datacolumn.field.fieldNewValue"),
                    prop: "fieldNewValue",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder:
                        _vm.$t("cip.cmn.rule.inputWarning") +
                        _vm.$t("cip.dc.datacolumn.field.fieldNewValue"),
                    },
                    model: {
                      value: _vm.form2.fieldNewValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.form2, "fieldNewValue", $$v)
                      },
                      expression: "form2.fieldNewValue",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("cip.dc.datacolumn.field.status"),
                    prop: "status",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form2.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form2, "status", $$v)
                        },
                        expression: "form2.status",
                      },
                    },
                    _vm._l(_vm.statusOptions, function (dict) {
                      return _c(
                        "el-radio",
                        { key: dict.id, attrs: { label: dict.itemText } },
                        [_vm._v(_vm._s(dict.itemValue))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("cip.dc.datacolumn.field.remark"),
                    prop: "remark",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder:
                        _vm.$t("cip.cmn.rule.inputWarning") +
                        _vm.$t("cip.dc.datacolumn.field.remark"),
                    },
                    model: {
                      value: _vm.form2.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form2, "remark", $$v)
                      },
                      expression: "form2.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitRecordForm },
                },
                [_vm._v(_vm._s(_vm.$t("submitText")))]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.record.open = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("cancelText")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }